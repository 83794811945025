import React, { useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./gallery.css";
import Icon from './icons';

export default function Gallery() {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const gallery = [
        {
            name: "Fattouch-Salat",
            description: "Gemischte Salate mit Minze, Tomate, Gurke, orientalischen Chips und einer Zitronen-Granatapfelsoße",
            img: '/img/falafel-brot.png',
        },
        {
            name: "Taboulé-Salat",
            description: "mit Bulgur, Petersilie, Tomaten und Zitronensoße (ohne Tomate nicht möglich)",
            img: '/img/baklava.png',
        },
        {
            name: "Gemischter Salat",
            description: "Blattsalate und Petersilie mit Tomaten, Gurke, Paprika und Radischen. Die hauseigene Soße ist vegetarisch.",
            img: '/img/falafel-teller.png',
        },
    ];

    return (
        <div className='new-gallery-wrapper'>
            <Slider ref={sliderRef} {...settings}>
                {gallery.map((g, index) => (
                    <div key={index} className="image-container">
                        <img src={g.img} alt={g.name} className="new-gallery-image" />
                    </div>
                ))}
            </Slider>
            <div className='gallery-buttons-wrapper'>
                <button className='new-gallery-button' onClick={() => sliderRef.current.slickNext()}>
                    <Icon variant="arrowForward" color='white' />
                </button>
                <button className='new-gallery-button' style={{ marginRight: "3px" }} onClick={() => sliderRef.current.slickPrev()}>
                    <Icon variant="arrowBack" color='white' />
                </button>
            </div>
        </div>
    );
}

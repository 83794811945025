import React, { useState, useEffect } from 'react';
import Icon from "./icons";

export default function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    return (
        <>
            {isVisible && (
                <div
                    onClick={scrollToTop}
                    style={{
                        padding: "20px 20px 15px 20px",
                        background: "black",
                        position: "fixed",
                        right: "10px",
                        bottom: "120px",
                        transform: "rotate(90deg)",
                        transformOrigin: "center",
                        zIndex: "50",
                        cursor: "pointer",
                        transition: "right 0.8s ease-out; /* Transition for sliding effect */"

                    }}
                >
                    <Icon variant="arrowBack" color='white' />
                </div>
            )}
        </>
    );
}

import './twoBoxes.css';

export default function TwoBoxes() {

    return (

        <div className='wrapper'>



            <div className='box-wrapper'>
                <div style={{
                    width: "100%",
                    height: "250px",
                    background: `url(/img/brunch.jpg) no-repeat`,
                    backgroundSize: "cover"
                }}>
                </div>

                <div style={{ padding: "0", marginTop: "25px", paddingRight: "10px" }}>
                    <h3>Orientalischer Brunch</h3>
                    <p >Jeden Sonntag euch erwarten viele kulinarische Köstlichkeiten, wie z. B. ein Hauptgericht – mit oder ohne Fleisch, viele Vorspeisen
                        und vieles mehr.</p>
                    <a href='#brunch'>
                        <button>

                            Mehr erfahren
                        </button>
                    </a>
                </div>
            </div>




            <div className='box-wrapper'>
                <div style={{
                    width: "100%",
                    height: "250px",
                    background: `url(/img/falafel-brot.png) no-repeat`,
                    backgroundSize: "cover"
                }}>
                </div>

                <div style={{ padding: "0", marginTop: "25px", paddingRight: "10px" }}>
                    <h3>Catering mit 1.001 Möglichkeit!</h3>

                    <p>Wir stellen das Essen ganz nach Deinen Wünschen zusammen – kalte oder warme Platten, vegetarisch oder mit Fleisch, Fingerfood oder Tellergerichte. </p>
                    <a href='#catering'>
                        <button>

                            Mehr erfahren
                        </button>
                    </a>
                </div>
            </div>
        </div >
    )
}
import React, { useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Icon from './icons';
import "./menuGallery.css";
import { useState } from 'react';

export default function MenuGallery() {

    const [isActiveTab, setIsActiveTab] = useState("beliebt");


    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const beliebt = [
        {
            name: "Baklava", description: "Das ist i einfach und bequem per Telefon oder per Gerne können Sie zu uns kommen ust ein Blindtext",
            img: '/img/baklava.png',
        },
        {
            name: "Salat", description: "Das is einfach und bequem per Telefon-Mail. Gerne können Sie zu uns kommen ut ist ein Blindtext",
            img: '/img/salat.png',
        },
        {
            name: "Schawarmabrot", description: "orientalisch gewürztes Fleisch, gemischter Salat, Tomaten, Gurken, Hummus, Zitronenkartoffeln, Zimtmöhren und Sesamsoße, in Weizenbrot gerollt",
            img: '/img/falafel-brot.png',
        },
        {
            name: "Falafel Teller", description: "Das is einfach und bequem per Telefon oder per E-Mail. GernSie zu uns kommen ut ist ein Blindtext",
            img: '/img/falafel-teller.png',
        },

    ];



    const teller = [
        {
            name: "Hummusteller", description: "Das ist i einfach und bequem per Telefon oder per Gerne können Sie zu uns kommen ust ein Blindtext",
            img: '/img/teller/hummusteller.png',
        },
        {
            name: "Falafelteller", description: "Falafel, Salat, Tomaten, Gurken, Hummus, Zimtmöhren, Zitronenkartoffeln, Walnussmus, und zwei weitere variierende Vorspeisen. Der Teller kann auch laktose- oder glutenfrei zubereitet werden.",
            img: '/img/teller/falafelteller.png',
        },
        {
            name: "Halloumiteller", description: "frittierter Halloumikäse, Salat, Tomaten, Gurken, Hummus, Zimtmöhren, Zitronenkartoffeln, Walnussmus, und zwei weitere variierende Vorspeisen. Der Teller kann auch laktose- oder glutenfrei zubereitet werden.",
            img: '/img/teller/halloumiteller.png',
        },
        {
            name: "Schwarmateller", description: "orientalisch gewürztes Hühnerfleisch, Reis, Salat, Tomaten, Gurken, Hummus, Zwiebellinsen. Auch glutenfrei möglich.",
            img: '/img/teller/shawarmateller.png',
        },
        {
            name: "Kebabteller", description: "Kebabfleisch in pikanter Tomatensoße , Salat, Tomaten, Gurken und Reis",
            img: '/img/teller/kebabteller.png',
        },
        {
            name: "Lammwurstteller", description: "pikante Lammwürste, Reis, Salat, Tomaten, Gurken, Hummus, Zwiebellinsen. Auch glutenfrei möglich.",
            img: '/img/teller/lammwurstteller.png',
        },
        {
            name: "Fleischspieße", description: "zwei Fleischspieße, Reis, Salat, Tomaten, Gurken, Hummus, Zwiebellinsen. Auch glutenfrei möglich.",
            img: '/img/teller/fleischspiessteller.png',
        },
        {
            name: "Kleiner Mazzateller", description: "vier Vorspeisen, welche leicht variieren können. Hummus, Weinblätter (vegan), Taboule, Walnussmus, Walnussmus. Gerne kann auch vegan, vegetarisch, laktose- oder glutenfrei geliefert werden.",
            img: '/img/falafel-teller.png',
        },
        {
            name: "Großer Mazzateller", description: "sechs Vorspeisen, welche leicht variieren können. Hummus, Weinblätter (vegan), Taboule, Walnussmus, sowie drei weitere Vorspeisen. Gerne kann auch vegan, vegetarisch, laktose- oder glutenfrei geliefert werden.",
            img: '/img/teller/mazzateller-gross.png',
        },
        {
            name: "Kubbeteller", description: "drei gefüllte Weizengrießtaschen, gefüllt mit Rinderhackfleisch und Pinienkernen, Taboule, Hummus, Salat mit Tomaten, Gurken und orientalischen Chips",
            img: '/img/falafel-teller.png',
        },
        {
            name: "Grillteller", description: "Kebab, Fleischspieß (Huhn), Lammwurst, Reis, gemischter Salat, Tomaten, Gurke, Hummus, Zwiebellinsen, pikante Brotecken",
            img: '/img/teller/grillteller.png',
        },

    ];

    const imBrot = [
        {
            name: "Falafelbrot", description: "mit frittiertem Halloumikäse, gemischtem Salat, Tomaten, Gurken, Hummus, Zitronenkartoffeln, Zimtmöhren und Sesamsoße, in Weizenbrot gerollt",
            img: '/img/brot/falafelbrot.png',
        },
        {
            name: "Halloumibrot", description: "Das is einfach und bequem per Telefon oder per E-Mail. GernSie zu uns kommen ut ist ein Blindtext",
            img: '/img/falafel-teller.png',
        },
        {
            name: "Schwarmabrot", description: "orientalisch gewürztes Fleisch, gemischter Salat, Tomaten, Gurken, Hummus, Zitronenkartoffeln, Zimtmöhren und Sesamsoße, in Weizenbrot gerollt",
            img: '/img/baklava.png',
        },
        {
            name: "Kebabbrot", description: "Kebabfleisch, gemischter Salat, Tomaten, Gurken, Hummus, Zitronenkartoffeln, Zimtmöhren und Sesamsoße, in Weizenbrot gerollt",
            img: '/img/salat.png',
        },
        {
            name: "Lammwurstbrot", description: "Lammwurst, gemischter Salat, Tomaten, Gurken, Hummus, Zitronenkartoffeln, Zimtmöhren und Sesamsoße, in Weizenbrot gerollt",
            img: '/img/salat.png',
        },
        {
            name: "Falafelbrot arabischer Art", description: "Falafel, gemischtem Salat, Tomaten, saure Gurken, Hummus, Minze, Sesamsoße, in Weizenbrot gerollt",
            img: '/img/salat.png',
        },
        {
            name: "Falafelbrot-Pommes", description: "mit Falafel, gemischtem Salat, Tomaten, Gurken, Hummus, Sesamsoße und Pommes, in Weizenbrot gerollt",
            img: '/img/salat.png',
        },


    ];

    const salat = [
        {
            name: "Fattouch-Salat", description: "Gemischte Salate mit Minze, Tomate, Gurke, orientalischen Chips und einer Zitronen-Granatapfelsoße",
            img: '/img/falafel-brot.png',
        },
        {
            name: "Taboulé-Salat", description: "mit Bulgur, Petersilie, Tomaten und Zitronensoße (ohne Tomate nicht möglich)",
            img: '/img/baklava.png',
        },
        {
            name: "Gemischter Salat", description: "Blattsalate und Petersilie mit Tomaten, Gurke, Paprika und Radischen. Die hauseigene Soße ist vegetarisch.",
            img: '/img/falafel-teller.png',
        },

    ];



    return (
        <div className='galleryWrapper' id="spezialitaeten" >

            <div className='galleryHeadline'>
            </div>

            <div className='galleryHeadline'>
                <h3>SPEZIALITÄTEN</h3>

                <div className='tagsWrapper'>

                    <div className="tags-container">


                        <button
                            className={`tags ${isActiveTab === "beliebt" ? "active" : ""}`}
                            onClick={() => setIsActiveTab("beliebt")}
                        >
                            sehr beliebt
                        </button>

                        <button
                            className={`tags ${isActiveTab === "imBrot" ? "active" : ""}`}
                            onClick={() => setIsActiveTab("imBrot")}
                        >
                            im Brot
                        </button>

                        <button
                            className={`tags ${isActiveTab === "teller" ? "active" : ""}`}
                            onClick={() => setIsActiveTab("teller")}
                        >
                            Tellergerichte
                        </button>


                        <button
                            className={`tags ${isActiveTab === "salat" ? "active" : ""}`}
                            onClick={() => setIsActiveTab("salat")}
                        >
                            Salate
                        </button>



                    </div>


                </div>
            </div>



            {isActiveTab === "beliebt" && (
                <div className='cardsWrapper'>
                    <Slider ref={sliderRef} {...settings}>
                        {beliebt.map((g, index) => (
                            <div key={index}>
                                <div className='card'>
                                    <div>
                                        <img src={g.img} alt={g.name} height="200px" width="100%" />
                                    </div>
                                    <div style={{ padding: "10px 20px 20px 20px", }}>
                                        <h4 style={{ fontWeight: "300" }}>{g.name}</h4>
                                        <p>{g.description}</p>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

                    <div className='galleryBottomWrapper'>
                        <div className='sliderButtonWrapper'>
                            <button className='slider-button' onClick={() => sliderRef.current.slickPrev()}>
                                <Icon variant="arrowBack" color='white' />
                            </button>

                            <button className='slider-button' style={{ marginLeft: "3px" }} onClick={() => sliderRef.current.slickNext()}>
                                <Icon variant="arrowForward" color='white' />
                            </button>
                        </div>
                    </div>
                </div>
            )}




            {isActiveTab === "imBrot" && (
                <div className='cardsWrapper'>
                    <Slider ref={sliderRef} {...settings}>
                        {imBrot.map((g, index) => (
                            <div key={index} style={{ boxSizing: "border-box", }}>
                                <div className='card'>
                                    <div>
                                        <img src={g.img} alt={g.name} height="200px" width="100%" />
                                    </div>
                                    <div style={{ padding: "10px 20px 20px 20px", }}>
                                        <h4 style={{ fontWeight: "300" }}>{g.name}</h4>
                                        <p>{g.description}</p>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

                    <div className='galleryBottomWrapper'>
                        <div className='sliderButtonWrapper'>
                            <button className='slider-button' onClick={() => sliderRef.current.slickPrev()}>
                                <Icon variant="arrowBack" color='white' />
                            </button>

                            <button className='slider-button' style={{ marginLeft: "3px" }} onClick={() => sliderRef.current.slickNext()}>
                                <Icon variant="arrowForward" color='white' />
                            </button>
                        </div>
                    </div>
                </div>
            )}



            {isActiveTab === "teller" && (
                <div className='cardsWrapper'>
                    <Slider ref={sliderRef} {...settings}>
                        {teller.map((g, index) => (
                            <div key={index} style={{ boxSizing: "border-box", }}>
                                <div className='card'>
                                    <div>
                                        <img src={g.img} alt={g.name} height="200px" width="100%" />
                                    </div>
                                    <div style={{ padding: "10px 20px 20px 20px", }}>
                                        <h4 style={{ fontWeight: "300" }}>{g.name}</h4>
                                        <p>{g.description}</p>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

                    <div className='galleryBottomWrapper'>
                        <div className='sliderButtonWrapper'>
                            <button className='slider-button' onClick={() => sliderRef.current.slickPrev()}>
                                <Icon variant="arrowBack" color='white' />
                            </button>

                            <button className='slider-button' style={{ marginLeft: "3px" }} onClick={() => sliderRef.current.slickNext()}>
                                <Icon variant="arrowForward" color='white' />
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isActiveTab === "salat" && (
                <div className='cardsWrapper'>
                    <Slider ref={sliderRef} {...settings}>
                        {salat.map((g, index) => (
                            <div key={index} style={{ boxSizing: "border-box", }}>
                                <div className='card'>
                                    <div>
                                        <img src={g.img} alt={g.name} height="200px" width="100%" />
                                    </div>
                                    <div style={{ padding: "10px 20px 20px 20px", }}>
                                        <h4 style={{ fontWeight: "300" }}>{g.name}</h4>
                                        <p>{g.description}</p>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

                    <div className='galleryBottomWrapper'>
                        <div className='sliderButtonWrapper'>
                            <button className='slider-button' onClick={() => sliderRef.current.slickPrev()}>
                                <Icon variant="arrowBack" color='white' />
                            </button>

                            <button className='slider-button' style={{ marginLeft: "3px" }} onClick={() => sliderRef.current.slickNext()}>
                                <Icon variant="arrowForward" color='white' />
                            </button>
                        </div>
                    </div>
                </div>
            )}



        </div >
    );

}
import React from 'react';
import Header from '../components/header';
// import ScrollToTop from '../components/scrollToTop';
import HeroStatic from '../components/heroStatic';
import TwoBoxes from '../components/twoBoxes';
import MenuGallery from '../components/menuGallery';
import Brunch from '../components/brunch';
import Delivery from '../components/delivery';
import Partyservice from '../components/partyservice';
import Faq from '../components/faq';
import Kontakt from '../components/kontakt';
import MenuCard from '../components/menuCard';
import ScrollToTop from '../components/scrollToTop';
import Footer from '../components/footer';
import DeliverButton from '../components/deliverButton';

function HomePage() {
    return (
        <>
            <main>
                <Header />
                <ScrollToTop />
                <DeliverButton />
                <HeroStatic />
                <TwoBoxes />
                <MenuGallery />
                <Brunch />
                <Delivery />
                <Partyservice />
                <Faq />
                <Kontakt />
                <Footer />
            </main>
        </>
    );
}

export default HomePage;

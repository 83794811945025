import './partyservice.css'


export default function Partyservice() {


    return (
        <div className="partyservice-wrapper" id='catering'>
            <div className='image-box'>
            </div>

            <div className='right-box'>
                <div className='text-wrapper'>
                    <h4 style={{ textAlign: "center" }}>Catering</h4>

                    <h3 style={{ textAlign: "center" }}>
                        PARTYSERVICE MIT 1.001 MÖGLICHKEIT!
                    </h3>

                    <p style={{ textAlign: "center" }}>
                        Wir zaubern Ihr Wunschmenü – ob kalt oder warm, vegetarisch oder mit Fleisch, Fingerfood oder Tellergericht. Frisch zubereitet und stilvoll dekoriert.
                        Auf Wunsch auch mit Getränken, Geschirr und Deko. Nennen Sie Ihre Wünsche, wir machen es möglich!
                    </p>





                    <button style={{ margin: "40px auto" }}>
                        Jetzt anfragen        <br /><b>

                        </b>
                    </button>

                </div>
            </div>
        </div >
    )
}
import './delivery.css'
import './kontakt.css';
import './global.css';



export default function Delivery() {

    return (
        <div className="delivery-wrapper" id='lieferservice'>

            <div className="box-with-text">
                <div className='delivery-text'>
                    <h4>Lieferservice</h4>
                    <h3>
                        HOL DIR DEN ORIENT NACH HAUSE!!
                    </h3>
                    <p>
                        Wer seine Bestellung zuhause genießen möchte oder es selbst abholen möchte, kann jetzt bei uns online bestellen.
                        <br /><br />
                        Erhalte <b>10€ Rabatt </b>auf deine nächsten 3 Bestellungen mit dem Code: FALAFEL – diesen kannst du am Ende des Bestellvorgangs unter "Rabattcode eingeben" einlösen
                    </p>

                    <div className='button-container'>
                        <a href='https://falafelfactory.online-karte.com/' target='_blank'>
                            <button> Jetzt online bestellen</button>
                        </a>

                    </div>
                </div>
            </div>

            <div className='smartphoneBox'>
            </div>
            {/*
            <div className='smartphoneBox' style={{
                width: "100%",
                background: "url(/public/img/smartphone.png) no-repeat center top",

            }}>
                hjhj
            </div> */}

        </div >
    )
}

export default function Footer() {
    return (
        <footer style={{

        }}>


            <ul style={{ color: "white", float: "right", marginRight: "0" }}>
                <li style={{ marginRight: "5px", fontFamily: "lato", fontSize: "16px", fontWeight: "300" }}>© Falafel Factory</li>


                <li style={{ marginRight: "5px", fontFamily: "lato", fontSize: "16px", fontWeight: "300" }}>
                    <a href="/impressum">

                        Impressum
                    </a>

                </li>
                <li style={{ marginRight: "10px", fontFamily: "lato", fontSize: "16px", fontWeight: "300" }}>

                    <a href="/datenschutz">
                        Datenschutz
                    </a>
                </li>
            </ul>
        </footer>
    )
}
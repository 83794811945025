import { useState } from "react";
import './menuCard.css';

export default function MenuCard() {
    const [isActiveTab, setIsActiveTab] = useState("tellerGerichte");
    const [openIndex, setOpenIndex] = useState(null);

    const toggleSection = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const tellerGerichte = [
        {
            img: '/img/baklava.png',
            meal: "Falafelteller",
            price: "7,50 Euro",
            description: "Falafel, Salat, Tomaten, Gurken, Hummus, Zimtmöhren, Zitronenkartoffeln, Walnussmus, und zwei weitere variierende Vorspeisen. Der Teller kann auch laktose- oder glutenfrei zubereitet werden."
        },
        {
            img: '/img/falafel-brot.png',
            meal: "Halloumiteller",
            price: "14,50 Euro",
            description: "frittierter Halloumikäse, Salat, Tomaten, Gurken, Hummus, Zimtmöhren, Zitronenkartoffeln, Walnussmus, und zwei weitere variierende Vorspeisen. Der Teller kann auch laktose- oder glutenfrei zubereitet werden."
        },
        {
            img: '/img/falafel-brot.png',
            meal: "Schwarmateller",
            price: "17,90 Euro",
            description: "orientalisch gewürztes Hühnerfleisch, Reis, Salat, Tomaten, Gurken, Hummus, Zwiebellinsen. Auch glutenfrei möglich."
        },
        {
            img: '/img/falafel-brot.png',
            meal: "Halloumiteller",
            price: "14,50 Euro",
            description: "frittierter Halloumikäse, Salat, Tomaten, Gurken, Hummus, Zimtmöhren, Zitronenkartoffeln, Walnussmus, und zwei weitere variierende Vorspeisen. Der Teller kann auch laktose- oder glutenfrei zubereitet werden."
        },
        {
            img: '/img/falafel-brot.png',
            meal: "Halloumiteller",
            price: "14,50 Euro",
            description: "frittierter Halloumikäse, Salat, Tomaten, Gurken, Hummus, Zimtmöhren, Zitronenkartoffeln, Walnussmus, und zwei weitere variierende Vorspeisen. Der Teller kann auch laktose- oder glutenfrei zubereitet werden."
        }
    ];

    const burger = [
        {
            img: '/img/falafel-brot.png',
            meal: "Halloumiteller",
            price: "14,50 Euro",
            description: "frittierter Halloumikäse, Salat, Tomaten, Gurken, Hummus, Zimtmöhren, Zitronenkartoffeln, Walnussmus, und zwei weitere variierende Vorspeisen. Der Teller kann auch laktose- oder glutenfrei zubereitet werden."
        },
        {
            img: '/img/falafel-brot.png',
            meal: "Halloumiteller",
            price: "14,50 Euro",
            description: "frittierter Halloumikäse, Salat, Tomaten, Gurken, Hummus, Zimtmöhren, Zitronenkartoffeln, Walnussmus, und zwei weitere variierende Vorspeisen. Der Teller kann auch laktose- oder glutenfrei zubereitet werden."
        }
    ];

    return (
        <div className="menu-wrapper">

            <div className="menu-header" style={{ backgroundImage: "" }}>
            </div>

            <div className="headline-tab-container">
                <h3 style={{ margin: "auto", textAlign: "center" }}>MENÜKARTE</h3>

                <div className="tagsWrapper">
                    <div className="tags-container" style={{ margin: "auto" }}>
                        <button
                            className={`tags ${isActiveTab === "tellerGerichte" ? "active" : ""}`}
                            onClick={() => setIsActiveTab("tellerGerichte")}
                        >
                            Hauptgerichte
                        </button>
                        <button
                            className={`tags ${isActiveTab === "imBrot" ? "active" : ""}`}
                            onClick={() => setIsActiveTab("imBrot")}
                        >
                            Im Brot
                        </button>
                        <button
                            className={`tags ${isActiveTab === "burger" ? "active" : ""}`}
                            onClick={() => setIsActiveTab("burger")}
                        >
                            Burger
                        </button>
                        <button
                            className={`tags ${isActiveTab === "salate" ? "active" : ""}`}
                            onClick={() => setIsActiveTab("salate")}
                        >
                            Salate
                        </button>
                        <button
                            className={`tags ${isActiveTab === "getraenke" ? "active" : ""}`}
                            onClick={() => setIsActiveTab("getraenke")}
                        >
                            Getränke
                        </button>
                    </div>
                </div>
            </div>

            {isActiveTab === "tellerGerichte" && (
                <>
                    {tellerGerichte.map((qa, index) => (
                        <div className="food-image-container" key={index}>
                            <div className="left-box-image">
                                <img src={qa.img} alt={qa.meal} height="100px" width="100px" />
                            </div>
                            <div style={{ margin: "0" }}>
                                <h5 style={{ fontWeight: "bold", fontSize: "18px", marginTop: "10px" }}>{qa.meal}</h5>
                                <p style={{ color: "#d2b48c", fontSize: "16px", fontWeight: "lighter", marginTop: "-15px" }}>{qa.description}</p>
                                <h5 style={{ fontWeight: "bold", fontSize: "20px", marginBottom: "15px" }}>{qa.price}</h5>
                            </div>
                        </div>
                    ))}
                </>
            )}

            {isActiveTab === "burger" && (
                <>
                    {burger.map((qa, index) => (
                        <div className="food-image-container" key={index} style={{ borderTop: "1px solid #bcb39f" }}>
                            <div style={{ alignItems: "center", padding: "10px 20px 10px 0" }}>
                                <img src={qa.img} alt={qa.meal} height="100px" width="100px" />
                            </div>
                            <div style={{ margin: "0" }}>
                                <h5 style={{ fontWeight: "bold", fontSize: "18px", marginTop: "10px" }}>{qa.meal}</h5>
                                <p style={{ color: "#d2b48c", fontSize: "16px", fontWeight: "lighter", marginTop: "-15px" }}>{qa.description}</p>
                                <h5 style={{ fontWeight: "bold", fontSize: "20px", marginBottom: "15px" }}>{qa.price}</h5>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

import './heroStatic.css';


export default function HeroStatic() {

    return (
        <div className="heroWrapper">
            <div className='heroImage' >


                <div className='hero-text-box' >

                    <h1>Frische ist das beste Rezept </h1>

                    <div className='show-text-desktop'>
                        {/* <p style={{
                            color: "white", fontSize: "18px", textShadow: "10px 10px 20px rgba(0, 0, 0, 1.5)", width: "400px",
                        }}>Seit über 30 Jahren bereiten wir orientalische Köstlichkeiten wie
                            z. B. Falafel, Tabuli oder Hummus zu! Dabei ist u. a. Frische und
                            Qualität unser oberstes Gebot!
                        </p> */}
                    </div>

                    <button className='showMoreOpener' style={{ border: "1px solid #F7C789", background: "#2D393B", padding: "15px 30px", marginTop: "40px" }}>
                        Mehr Erfahrenn
                    </button>



                </div>




                <div className='stoerer'>

                    <a>


                    </a>
                    <p style={{ color: "white", fontWeight: "300", textAlign: "center", marginTop: "5px" }}>
                        🔥 <br />Website in Bearbeitung! Bestellen aber möglich!
                    </p>
                </div>



            </div>


            <div className='show-mobile' >
                <p>Seit über 20 Jahren bereiten wir <b>orientalische Köstlichkeiten</b> wie
                    z. B. Falafel, Tabuli oder Hummus zu! Dabei ist u. a. <b>Frische und
                        Qualität </b>unser oberstes Gebot!</p>

                <button >Mehr erfahren
                </button>

            </div>


        </div>
    )
}
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/homepage';
import NewPage from './pages/newPage';
import Impressum from './pages/impressum';
import Datenschutz from './pages/datenschutz';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/new-page" element={<NewPage />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/datenschutz" element={<Datenschutz />} />



            </Routes>
        </Router>
    );
}

export default App;

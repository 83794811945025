import { useState } from "react";
import './faq.css';

export default function Faq() {
    const [isActiveTab, setIsActiveTab] = useState("generalFaq");
    const [openIndex, setOpenIndex] = useState(null);

    const toggleSection = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const questionAnswer = [
        { question: "Wie kann man die Falafel Factory erreichen?", answer: "Mit der U3 sind wir wunderbar erreichbar. Die U-Bahnstation (Dehnhaide) befindet sich nur 10m von der U-Bahnstation entfernt." },
        { question: "Ist die Falafel Factory barrierefrei?", answer: "Der Eingang ist ebenerdig und somit auch für Rollstuhlfahrer gut zu bewältigen. Die Eingänge der Toiletten sind allerdings zu schmal für Rollstühle." },
        { question: "Dürfen Hunde in das Restaurant? ", answer: "Hunde die sich zu benehmen wissen sind jederzeit herzlich willkommen. Frauchen und Herrchen selbstverständlich auch!" },
    ];

    const partyserviceFaq = [
        { question: "Für wieviel Personen kann ich Catering ordern?", answer: "Wir bereiten unser Catering ab 6 bis zu 200 Personen zu. Gegen einen Aufpreis, je nach Entfernung, liefern wir auch." },
        { question: "Wird Besteck mit geliefert?", answer: "Mit der U3 sind wir wunderbar erreichbar. Die U-Bahnstation (Dehnhaide) befindet sich nur 10m von der U-Bahnstation entfernt." },
        { question: "Ist die Falafel Factory barrierefrei?", answer: "Der Eingang ist ebenerdig und somit auch für Rollstuhlfahrer gut zu bewältigen. Die Eingänge der Toiletten sind allerdings zu schmal für Rollstühle." },
        { question: "Dürfen Hunde in das Restaurant? ", answer: "Hunde die sich zu benehmen wissen sind jederzeit herzlich willkommen. Frauchen und Herrchen selbstverständlich auch!" },
    ];

    const brunchFaq = [
        { question: "Muss man für den Sonntagsbrunch reservieren?", answer: "Wir bereiten unser Catering ab 6 bis zu 200 Personen zu. Gegen einen Aufpreis, je nach Entfernung, liefern wir auch." },
        { question: "Zahlt man für Kinder den selben Preis wie für Erwachsene?", answer: "Mit der U3 sind wir wunderbar erreichbar. Die U-Bahnstation (Dehnhaide) befindet sich nur 10m von der U-Bahnstation entfernt." },
    ];

    return (
        <div className="faq-wrapper">
            <h3>FRAGEN & ANTWORTEN</h3>
            <div style={{ display: "flex" }}>
                <button
                    className={`tags ${isActiveTab === "generalFaq" ? "active" : ""}`}
                    onClick={() => setIsActiveTab("generalFaq")}
                >
                    Allgemeine
                </button>
                <button
                    className={`tags ${isActiveTab === "partyserviceFaq" ? "active" : ""}`}
                    onClick={() => setIsActiveTab("partyserviceFaq")}
                >
                    Partyservice
                </button>
                <button
                    className={`tags ${isActiveTab === "brunchFaq" ? "active" : ""}`}
                    onClick={() => setIsActiveTab("brunchFaq")}
                >
                    Brunch
                </button>
            </div>

            {isActiveTab === "generalFaq" && (
                <div style={{ borderTop: "1px solid  #F7C789" }}>
                    {questionAnswer.map((qa, index) => (
                        <div key={index} style={{ borderBottom: "1px solid  #F7C789" }}>
                            <div
                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                                onClick={() => toggleSection(index)}
                            >
                                <h5>{qa.question}</h5>
                                <div><h5 style={{ fontWeight: "300" }}>{openIndex === index ? "-" : "+"}</h5></div>
                            </div>
                            {openIndex === index && (
                                <div style={{ marginTop: "-20px" }}>
                                    <p>{qa.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {isActiveTab === "partyserviceFaq" && (
                <div style={{ borderTop: "1px solid  #F7C789" }}>
                    {partyserviceFaq.map((qa, index) => (
                        <div key={index} style={{ borderBottom: "1px solid   #F7C789" }}>
                            <div
                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                                onClick={() => toggleSection(index)}
                            >
                                <h5>{qa.question}</h5>
                                <div><h5 style={{ fontWeight: "300" }}>{openIndex === index ? "-" : "+"}</h5></div>
                            </div>
                            {openIndex === index && (
                                <div style={{ marginTop: "-20px" }}>
                                    <p>{qa.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {isActiveTab === "brunchFaq" && (
                <div style={{ borderTop: "1px solid #F7C789" }}>
                    {brunchFaq.map((qa, index) => (
                        <div key={index} style={{ borderBottom: "1px solid   #F7C789" }}>
                            <div
                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                                onClick={() => toggleSection(index)}
                            >
                                <h5>{qa.question}</h5>
                                <div><h5 style={{ fontWeight: "300" }}>{openIndex === index ? "-" : "+"}</h5></div>
                            </div>
                            {openIndex === index && (
                                <div style={{ marginTop: "-20px" }}>
                                    <p>{qa.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

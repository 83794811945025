import React, { useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./brunch.css";
import Gallery from './gallery';


export default function Brunch() {







    return (
        <div className="brunch-wrapper" id='brunch'>

            <div className="image-gallery-container">

                <Gallery />

            </div>

            <div className="brunch-text-box">
                <div style={{ width: "90%", margin: "auto", textAlign: "center", }}>
                    <h4 style={{ textAlign: "center" }}>Jeden Sonntag</h4>
                    <h3 style={{ textAlign: "center" }}>
                        ORIENTALISCH BRUNCHEN                    </h3>
                    <p style={{ textAlign: "center", }}>
                        Jeden Sonntag, von 11 bis 15 Uhr, gibt es unseren Orientalischen-Brunch.
                        Euch erwarten viele kulinarische Köstlichkeiten, wie z. B. ein Hauptgericht –
                        mit oder ohne Fleisch, viele Vorspeisen – von Hummus bis Tabulisalat, Obst,
                        Eier, diverser Käse, Schokoaufstrich, Aufschnitt, Nachspeisen, Kuchen und vieles mehr.
                    </p>
                    <p style={{ textAlign: "center", }}>

                        Ganz gleich, ob Veganer, Vegetarier oder Allergiker, jeder wird bei unserem Brunch Speisen finden, die er mag!
                    </p>

                    <p style={{ textAlign: "center", }}>
                        Der Brunch kostet <b>17,90 €</b>.<br />
                    </p>
                    <a href='https://falafelfactory.tischreservieren.com/'>

                        <button style={{ margin: "30px auto", }}>Tisch reservieren</button>
                    </a>
                </div>
            </div>

        </div >
    )
}
import { useState } from 'react';
import Icon from './icons';
import './navigation.css';

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);

    function scrollToOffset(offset) {
        window.scrollBy({
            top: offset,
            behavior: 'smooth'
        });
    }

    return (
        <header>
            <div className='logo-container'>
                <img src="/img/falafel-factory-logo.jpg"></img>
            </div>
            <div className='navigation'>


                <div className="nav-desktop">
                    <ul className='desktop-menu'>
                        <li><a href="#spezialitaeten" onClick={() => scrollToOffset(50)}>Spezialitäten</a></li>
                        <li><a href="#brunch" onClick={() => scrollToOffset(50)}>Brunch</a></li>
                        <li><a href="#lieferservice" onClick={() => scrollToOffset(50)}>Lieferservice 🔥</a></li>
                        <li><a href="#catering" onClick={() => scrollToOffset(50)}>Catering</a></li>
                        <li><a href="#kontakt" onClick={() => scrollToOffset(50)}>Kontakt</a></li>
                    </ul>
                </div>

                <div className='burgerMenu' onClick={() => setIsOpen(!isOpen)}>
                    <Icon variant={isOpen ? "cancel" : "menu"} size="40" color='#FFC77D' />
                </div>

                {isOpen && (
                    <div className='mobile-menu'>
                        <ul className='nav'>
                            <li><a href="#spezialitaeten" onClick={() => setIsOpen(!isOpen)}> <h3 style={{ textAlign: "center" }}>Spezialitäten</h3></a></li>
                            <li><a href="#brunch" onClick={() => setIsOpen(!isOpen)}><h3 style={{ textAlign: "center" }}>Brunch</h3></a></li>
                            <li><a href="#lieferservice" onClick={() => setIsOpen(!isOpen)}><h3 style={{ textAlign: "center" }}>Lieferservice 🔥 </h3></a></li>
                            <li><a href="#catering" onClick={() => setIsOpen(!isOpen)}><h3 style={{ textAlign: "center" }}>Catering</h3></a></li>
                            <li><a href="#kontakt" onClick={() => setIsOpen(!isOpen)}><h3 style={{ textAlign: "center" }}>Kontakt</h3></a></li>
                            <li style={{ fontSize: "16px", marginTop: "50px" }}><a href="/impressum" onClick={() => setIsOpen(false)}>Impressum</a></li>
                            <li style={{ fontSize: "16px", marginTop: "-20px" }}><a href="/datenschutz" onClick={() => setIsOpen(false)}>Datenschutz</a></li>
                        </ul>
                    </div>
                )}
            </div>
        </header>
    );
}

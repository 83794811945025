import './deliverButton.css';


export default function DeliverButton() {
    return (
        <div className='deliverButtonWrapper'>
            <div className='deliver-button'>
                <a style={{ textTransform: "none", }} href='https://falafelfactory.online-karte.com/'>

                    <p style={{ textAlign: "center", fontWeight: "400" }}>
                        Online bestellen
                    </p>
                </a>
            </div>

            <div className='reserve-button'>
                <a style={{ textTransform: "none" }} href='https://falafelfactory.tischreservieren.com/'>

                    <p style={{ textAlign: "center", fontWeight: "400" }}>
                        Tisch reservieren
                    </p>
                </a>
            </div>
        </div>
    )
}
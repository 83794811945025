import React from 'react';
import './kontakt.css';
import Footer from './footer';

export default function Kontakt() {
    return (
        <div className="kontakt-container" id='kontakt'>
            <div className="kontakt-iframe">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2368.781258411069!2d10.0409192!3d53.5795204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b1894a783af6b5%3A0xa5d4dbc8bddf7cbb!2sFalafel%20Factory!5e0!3m2!1sde!2sde!4v1720448444062!5m2!1sde!2sde"
                    width="100%"
                    height="100%"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    style={{ border: "none" }}
                ></iframe>
            </div>

            <div className="kontakt-info">
                <div className="kontakt-info-inner">
                    <h3>
                        KONTAKT
                    </h3> <br />
                    <p><b>FALAFEL FACTORY</b><br /><br />
                        Barmbeker Markt 40<br />
                        22081 Hamburg<br />
                        Tel.: 040 18139990
                        <br /><br />

                        <b>Öffnungszeiten:</b><br />
                        So – Mi von 11:00 bis 22:00 Uhr<br /><br />

                        Do – Sa von 11:00 bis 23:00/23:30 Uhr
                    </p>
                    <br /><br />

                </div>
            </div>
        </div>
    );
}

import React from 'react';
import Header from '../components/header';

export default function Impressum() {
    return (
        <>
            <Header />


            <div style={{ margin: "30px auto", padding: "10px", maxWidth: " 1000px" }}>



                <h3 style={{ textAlign: "center" }}>Impressum</h3>

                <p style={{ textAlign: "center" }}>

                    Falafel Factory<br /><br />

                    Barmbeker Markt 40<br /><br />

                    22081 Hamburg<br /><br />



                    Tel.: 43/040/04512<br /><br />



                    info@falafel-factory.de<br /><br />

                    www.falafel-factory.de<br /><br />



                    Steuernummer: 71/510/17139<br /><br />

                    Verantwortlich für den Inhalt: <br />Sabine Schmidtwilken-Cnaan

                </p>


            </div>
        </>
    );
}


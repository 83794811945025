import React from 'react';
import MenuCard from '../components/menuCard';

function NewPage() {
    return (
        <div style={{ margin: "auto", padding: "10px", maxWidth: " 1000px" }}>

            {/* <div style={{
                maxWidth: "1000px",
                height: "200px",
                marginBottom: "-50px",
                background: "url(/img/menu-banner.png) no-repeat center cover red",
                backgroundSize: "cover",

            }}
            ></div> */}

            <MenuCard />
        </div>
    );
}

export default NewPage;
